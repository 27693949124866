<template>
	<div>
		<b-modal
			id="modal-add-batch-adjustment"
			cancel-variant="outline-secondary"
			ok-title="Ok"
			cancel-title="Close"
			ok-variant="primary"
			centered
			title="New Batch Adjustment"
			:no-close-on-backdrop="true"
			scrollable
			@ok="confirmAddBatchAdjustment"
			@hidden="resetForm"
		>
			<b-card no-body>
				<b-row>
					<b-col
						cols="12"
						md="4"
					>
						<label class="mb-1">Batch Name</label>
						<b-form-input
							v-model="filter.batchName"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							class="w-100"
						/>
					</b-col>
					<b-col
						cols="12"
						md="8"
					>
						<label class="mb-1">
							<span class="text-danger">*</span> Batch file
							<a
								class="ml-1"
								href="/src/assets/files/example_file.xlsx"
								download
							>
								example_file.xlsx
							</a>
						</label>
						<!-- Max Amount -->
						<b-form-group>
							<!-- Attach -->
							<validation-provider
								#default="{ errors }"
								name="attachments"
								rules="required"
							>
								<b-form-file
									id="attachments"
									v-model="batchAjustmentData.attachments"
									accept=".xls, .xlsx, .csv"
									@change="uploadFileAdjustment($event)"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
				</b-row>
			</b-card>
			<b-card no-body>
				<b-table
					ref="refBatchAdjustmentTable"
					class="position-relative table-white-space mb-0"
					:items="fetchBatchAdjustmentLists"
					responsive
					:fields="tableColumns"
					primary-key="index"
					show-empty
					empty-text="No matching records found"
				>
					<template #cell(index)="data">
						{{ perPage * (currentPage - 1) + data.index + 1 }}
					</template>

				</b-table>
				<div class="p-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="currentPage"
								:total-rows="totalBatchAdjustment"
								:per-page="perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
	BFormFile, BInputGroupAppend, BInputGroup,
	BCardBody,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import { numberFormat, formatDateTime, resolveCurrency, currencyOptions } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BCardBody,
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BFormFile,
		BInputGroupAppend,
		BInputGroup,
		BPagination,

		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			required,
			// file_example: require('@/assets/files/example_file.xlsx'),
			filter: {
				batchName: '',
			},
			batchAjustmentData: {
				batchName: '',
				attachments: null,
				attachmentsName: '',
			},
		}
	},
	methods: {
		uploadFileAdjustment(event) {
		},
	},
	setup() {
		/* if (!store.hasModule('payment-type')) store.registerModule('payment-type', paymentTypeStoreModule)
		onUnmounted(() => {
			if (store.hasModule('payment-type')) store.unregisterModule('payment-type')
		}) */
		// const toast = useToast()
		const perPage = ref(20)
		const totalBatchAdjustment = ref(0)
		const currentPage = ref(1)
		const autoTypeFilter = ref(null)
		const currencyFilter = ref(null)
		const refBatchAdjustmentTable = ref(null)
		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'adjustment_type', label: 'Adjustment Type' },
			{ key: 'username', label: 'Login Name' },
			{ key: 'amount', label: 'Amount' },
			{ key: 'reason', label: 'Reason' },
			{ key: 'note', label: 'Note' },
			{ key: 'message', label: 'Message' },
			{ key: 'status', label: 'Status' },
		]

		const refetchData = () => {
			refBatchAdjustmentTable.value.refresh()
		}

		watch([currentPage, autoTypeFilter, currencyFilter], () => {
			refetchData()
		})

		const fetchBatchAdjustmentLists = [
			{
				id: 1,
				adjustment_type: 'test',
				username: 'demo',
				amount: 5000,
				reason: 'nothing',
				note: 'test',
				message: 'test',
				status: 'success',
			}
		]

		totalBatchAdjustment.value = fetchBatchAdjustmentLists.length

		/* const fetchPaymentTypes = (ctx, callback) => {
			store
				.dispatch('payment-type/fetchPaymentTypes', {
					page: currentPage.value,
					name: nameFilter.value,
					code: codeFilter.value,
					status: statusFilter.value,
					type: transactionTypeFilter.value,
				})
				.then(response => {
					if (response.data.code === '00') {
						callback(response.data.data)
						totalPaymentTypes.value = response.data.count
						perPage.value = currentPage.value === 1 ? response.data.data.length : 25
					} else {
						toast({
							component: ToastificationContent,
							props: {
								title: response.data.message,
								icon: 'AlertTriangleIcon',
								variant: 'danger',
							},
						})
					}
				})
				.catch(error => {
					const { response } = error
					toast({
						component: ToastificationContent,
						props: {
							title: response.data.message || 'Error fetching cash logs list',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		} */

		const dataMeta = computed(() => {
			const localItemsCount = refBatchAdjustmentTable.value ? refBatchAdjustmentTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalBatchAdjustment.value,
			}
		})

		const autoTypeOptions = [
			{ label: '1 Click Pay', value: 1 },
			{ label: '2Help', value: 2 },
			{ label: 'Wire', value: 3 },
		]

		return {
			fetchBatchAdjustmentLists,
			tableColumns,
			perPage,
			currentPage,
			totalBatchAdjustment,
			dataMeta,
			refBatchAdjustmentTable,

			refetchData,

			autoTypeFilter,
			currencyFilter,

			formatDateTime,
			numberFormat,
			resolveCurrency,
			autoTypeOptions,
			currencyOptions,
		}
	},
}
</script>